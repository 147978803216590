import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import "../styles/stats-and-standings.scss"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"

export default ({ location }) => {
    const { teams, divisions, players } = useStaticQuery(graphql`
    query StatsAndStandingsQuery {
        teams: allTeamsJson {
          edges {
            node {
              division
              record {
                wins
                losses
              }
              title
              initials
            }
          }
        }
        divisions: allDivisionsJson {
        edges {
        node {
            divisionList {
                divisionName
              }
            }
          }
        }
        players: allTeamsJson {
            edges {
              node {
                division
                roster {
                  offStats {
                    hits
                    games
                    doubles
                    homeRuns
                    runs
                    walks
                    atBats
                    triples
                    strikeouts
                    rbis
                    stolenBases
                    hitByPitches
                    plateAppearances
                  }
                  pitchStats {
                    earnedRuns
                    games
                    gamesStarted
                    hitBatters
                    hits
                    homeRuns
                    innings
                    losses
                    runs
                    saves
                    stolenBases
                    strikeouts
                    walks
                    wins
                  }
                  name
                  batHandedness
                  position
                  throwHandedness
                }
                initials
              }
            }
          }
      }
    `)

    return (
        <Layout location={location} cssPageName="stats-and-standings">
            <SEO title="Stats & Standings" />
            <Standings teams={teams} />
            <hr />
            <Stats teams={teams} divisions={divisions} players={players.edges} />
        </Layout >
    )
}

const Standings = ({ teams }) => {

    const [standings, setStandings] = useState({})

    const allTeams = teams.edges

    useEffect(() => {
        const newStandings = {}

        allTeams.forEach(({ node }) => {
            newStandings[node.division] = []
        })

        allTeams.forEach(({ node }) => {
            newStandings[node.division].push({ ...node, score: node.record.wins * .5 - node.record.losses * .5 })
        })

        for (const division in newStandings) {
            newStandings[division].sort((a, b) => {
                return b.score - a.score
            })
        }

        for (const division in newStandings) {
            // eslint-disable-next-line
            newStandings[division].map((team, index) => {
                if (index === 0) team.position = "1"

                //If it isn't the first team in the array...
                if (index > 0) {
                    //Check the previous team's record score for equality with current team
                    if (team.score === newStandings[division][index - 1].score) {
                        if (newStandings[division][index - 1].position[0] !== "T") {
                            newStandings[division][index - 1].position = "T" + newStandings[division][index - 1].position
                        }
                        return team.position = "T" + String(newStandings[division][index - 1].position).charAt(newStandings[division][index - 1].position.length - 1)
                    } else {
                        return team.position = String(index + 1)
                    }
                }
            })
        }

        setStandings(newStandings)
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <h1 className="standings-title">Standings</h1>
            <section className="standings-container">
                {Object.entries(standings).map((division, index) => (
                    <div key={index} className="division-container">
                        <p className="division-name">{division[0]}</p>
                        <div className="team-listing">
                            <span className="heading heading-record">Record</span>
                            <span className="heading heading-score">GB</span>
                            {division[1].map((team, index) => (
                                <React.Fragment key={index}>
                                    <span className="team-name">{team.position}.&nbsp;{team.title.replace(" ", "\u00a0")}</span>
                                    <span className="record">{team.record.wins}-{team.record.losses}</span>
                                    <span className="score">-{division[1][0].score - team.score === 0 ? "-" : division[1][0].score - team.score}</span>
                                </React.Fragment>
                            ))}
                        </div>
                    </div>
                ))}
            </section>
        </>
    )
}

const Stats = ({ teams, divisions, players: playersWithoutTeams }) => {
    const [statCategory, setStatCategory] = useState("hitting")
    const [team, setTeam] = useState("all")
    const [division, setDivision] = useState("all")
    const [position, setPosition] = useState("all")
    const [sortByColumn, setSortByColumn] = useState(["OPS", "offStats", 0])

    const offStatsCalculator = (statType, data) => {
        const { plateAppearances, atBats, hits, doubles, triples, homeRuns, hitByPitches, walks } = data

        const normalize = value => String(value.toFixed(3).replace(/^0+/, ""))

        if (statType === "AVG") {
            return normalize(hits / atBats)
        }

        if (statType === "OBP") {
            return normalize((hits + walks + hitByPitches) / plateAppearances)
        }

        if (statType === "SLG") {
            return normalize(((hits - doubles - triples - homeRuns) + (2 * doubles) + (3 * triples) + (4 * homeRuns)) / atBats)
        }

        if (statType === "OPS") {
            return normalize((((hits + walks + hitByPitches) / plateAppearances) + (((hits - doubles - triples - homeRuns) + (2 * doubles) + (3 * triples) + (4 * homeRuns)) / atBats)))
        }
    }

    const [players] = useState(playersWithoutTeams.map(({ node }) => {
        return node.roster.map((player) => ({
            ...player,
            team: node.initials,
            division: node.division,
            offStats: {
                ...player.offStats,
                battingAverage: offStatsCalculator("AVG", player.offStats),
                onBasePercentage: offStatsCalculator("OBP", player.offStats),
                sluggingPercentage: offStatsCalculator("SLG", player.offStats),
                onBasePlusSlugging: offStatsCalculator("OPS", player.offStats)
            },
            pitchStats: {
                ...player.pitchStats,
                era: (player.pitchStats.runs * 9 / player.pitchStats.innings).toFixed(2),
                whip: ((player.pitchStats.hits + player.pitchStats.walks) / player.pitchStats.innings).toFixed(2)
            }
        }))
    }).flat(2))

    //This does not need it's own state because it will change whenever any of the other states above this line change.
    // The re-render will update this constant according to the current array of states.
    const sortedPlayers = players
        .filter(player => statCategory === "hitting" ? player.offStats.games > 0 : player.pitchStats.games > 0)
        .filter(player => team === "all" ? player : player.team === team)
        .filter(player => division === "all" ? player : player.division === division)
        .filter(player => position === "all" ? player : player.position === position)
        // eslint-disable-next-line
        .sort((a, b) => {
            //Turn 0 or 1 into descending/ascending order
            const order = sortByColumn[2] === 0 ? "DESC" : "ASC"

            if (order === "DESC") {
                // If we're sorting on one of the alphabetical fields (AKA not a field nested in the stats object)...
                if (sortByColumn[0] === "name" || sortByColumn[0] === "team") {
                    if (a[sortByColumn[0]] < b[sortByColumn[0]]) return -1
                    if (b[sortByColumn[0]] < a[sortByColumn[0]]) return 1
                    return 0
                    // Or else we're just sorting numbers from the stats object
                } else {
                    if (a[sortByColumn[1]][sortByColumn[0]] > b[sortByColumn[1]][sortByColumn[0]]) return -1
                    if (b[sortByColumn[1]][sortByColumn[0]] > a[sortByColumn[1]][sortByColumn[0]]) return 1
                    return 0
                }
            }

            if (order === "ASC") {
                // If we're sorting on one of the alphabetical fields (AKA not a field nested in the stats object)...
                if (sortByColumn[0] === "name" || sortByColumn[0] === "team") {
                    if (a[sortByColumn[0]] < b[sortByColumn[0]]) return 1
                    if (b[sortByColumn[0]] < a[sortByColumn[0]]) return -1
                    return 0
                    // Or else we're just sorting numbers from the stats object
                } else {
                    if (a[sortByColumn[1]][sortByColumn[0]] > b[sortByColumn[1]][sortByColumn[0]]) return 1
                    if (b[sortByColumn[1]][sortByColumn[0]] > a[sortByColumn[1]][sortByColumn[0]]) return -1
                    return 0
                }
            }
        })

    const handleTableSwitch = (e) => {
        setStatCategory(e.target.value)
        setPosition("all")
    }

    const handleSort = (value, gameSide) => {
        if (sortByColumn[0] === value && sortByColumn[1] === 1) {
            setSortByColumn([value, gameSide, 0])
        } else if (sortByColumn[0] === value) {
            setSortByColumn([value, gameSide, 1])
        } else {
            setSortByColumn([value, gameSide, 0])
        }
    }

    return (
        <section id="stats" className="stats-container">
            <h1 className="stats-title">Stats</h1>

            <div className="filters-container">
                {/* eslint-disable-next-line */}
                <select className="select table-type-select" value={statCategory} onChange={handleTableSwitch}>
                    <option value="hitting">Hitting</option>
                    <option value="pitching">Pitching</option>
                </select>

                <select className="select team-select" value={team} onBlur={(e) => setTeam(e.target.value)} onChange={(e) => setTeam(e.target.value)}>
                    <option className="hidden" value="all" disabled>Team</option>
                    <option value="all">All</option>
                    {teams.edges.map(team => <option key={team.node.initials} value={team.node.initials}>{team.node.initials}</option>)}
                </select>

                <select className="select division-select" value={division} onBlur={(e) => setDivision(e.target.value)} onChange={(e) => setDivision(e.target.value)}>
                    <option className="hidden" value="all" disabled>Division</option>
                    <option value="all">All</option>
                    {divisions.edges[0].node.divisionList.map(division => <option key={division.divisionName} value={division.divisionName}>{division.divisionName}</option>)}
                </select>

                <select className="select position-select" value={position} onBlur={(e) => setPosition(e.target.value)} onChange={(e) => setPosition(e.target.value)}>
                    <option className="hidden" value="all" disabled>Position</option>
                    <option value="all">All</option>
                    {["P", "SP", "RP", "CP", "RHP", "LHP", "C", "INF", "1B", "2B", "3B", "SS", "OF", "LF", "CF", "RF", "U"].map(position => <option key={position} value={position}>{position}</option>)}
                </select>
            </div>
            {statCategory === "hitting" && <div className="hitting-table-container">
                <p className="sort-instruction">(Click a column header to sort.)</p>
                <table className="hitting-table">
                    <thead className="table-header">
                        <tr>
                            <th className="player align-left"><button onClick={() => handleSort("name", "offStats")}>Player</button></th>
                            <th className="team align-right"><button onClick={() => handleSort("team", "offStats")}>Team</button></th>
                            <th className="games align-right"><button onClick={() => handleSort("games", "offStats")}>G</button></th>
                            <th className="at-bats align-right"><button onClick={() => handleSort("atBats", "offStats")}>AB</button></th>
                            <th className="runs align-right"><button onClick={() => handleSort("runs", "offStats")}>R</button></th>
                            <th className="hits align-right"><button onClick={() => handleSort("hits", "offStats")}>H</button></th>
                            <th className="doubles align-right"><button onClick={() => handleSort("doubles", "offStats")}>2B</button></th>
                            <th className="triples align-right"><button onClick={() => handleSort("triples", "offStats")}>3B</button></th>
                            <th className="home-runs align-right"><button onClick={() => handleSort("homeRuns", "offStats")}>HR</button></th>
                            <th className="rbis align-right"><button onClick={() => handleSort("rbis", "offStats")}>RBI</button></th>
                            <th className="walks align-right"><button onClick={() => handleSort("walks", "offStats")}>BB</button></th>
                            <th className="strikeouts align-right"><button onClick={() => handleSort("strikeouts", "offStats")}>SO</button></th>
                            <th className="hit-by-pitches align-right"><button onClick={() => handleSort("hitByPitches", "offStats")}>HBP</button></th>
                            <th className="stolen-bases align-right"><button onClick={() => handleSort("stolenBases", "offStats")}>SB</button></th>
                            <th className="batting-average align-right"><button onClick={() => handleSort("battingAverage", "offStats")}>AVG</button></th>
                            <th className="on-base-percentage align-right"><button onClick={() => handleSort("onBasePercentage", "offStats")}>OBP</button></th>
                            <th className="slugging-percentage align-right"><button onClick={() => handleSort("sluggingPercentage", "offStats")}>SLG</button></th>
                            <th className="on-base-plus-slugging align-right"><button onClick={() => handleSort("onBasePlusSlugging", "offStats")}>OPS</button></th>
                        </tr>
                    </thead>
                    <tbody className="table-body">
                        {sortedPlayers.map((player) => (
                            <tr key={player.name}>
                                <td className="align-left">{player.name}</td>
                                <td className="align-right">{player.team}</td>
                                <td className="align-right">{player.offStats.games}</td>
                                <td className="align-right">{player.offStats.atBats}</td>
                                <td className="align-right">{player.offStats.runs}</td>
                                <td className="align-right">{player.offStats.hits}</td>
                                <td className="align-right">{player.offStats.doubles}</td>
                                <td className="align-right">{player.offStats.triples}</td>
                                <td className="align-right">{player.offStats.homeRuns}</td>
                                <td className="align-right">{player.offStats.rbis}</td>
                                <td className="align-right">{player.offStats.walks}</td>
                                <td className="align-right">{player.offStats.strikeouts}</td>
                                <td className="align-right">{player.offStats.hitByPitches}</td>
                                <td className="align-right">{player.offStats.stolenBases}</td>
                                <td className="align-right">{player.offStats.battingAverage}</td>
                                <td className="align-right">{player.offStats.onBasePercentage}</td>
                                <td className="align-right">{player.offStats.sluggingPercentage}</td>
                                <td className="align-right">{player.offStats.onBasePlusSlugging}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>}
            {statCategory === "pitching" && <div className="pitching-table-container">
                <p className="sort-instruction">(Click a column header to sort.)</p>
                <table className="pitching-table">
                    <thead className="table-header">
                        <tr>
                            <th className="player align-left"><button onClick={() => handleSort("name", "pitchStats")}>Player</button></th>
                            <th className="team align-right"><button onClick={() => handleSort("team", "pitchStats")}>Team</button></th>
                            <th className="wins align-left"><button onClick={() => handleSort("wins", "pitchStats")}>W</button></th>
                            <th className="losses align-right"><button onClick={() => handleSort("losses", "pitchStats")}>L</button></th>
                            <th className="era align-right"><button onClick={() => handleSort("era", "pitchStats")}>ERA</button></th>
                            <th className="games align-right"><button onClick={() => handleSort("games", "pitchStats")}>G</button></th>
                            <th className="games-started align-right"><button onClick={() => handleSort("gamesStarted", "pitchStats")}>GS</button></th>
                            <th className="saves align-right"><button onClick={() => handleSort("saves", "pitchStats")}>S</button></th>
                            <th className="innings-pitched align-right"><button onClick={() => handleSort("innings", "pitchStats")}>IP</button></th>
                            <th className="earned-runs align-right"><button onClick={() => handleSort("earnedRuns", "pitchStats")}>ER</button></th>
                            <th className="runs align-right"><button onClick={() => handleSort("runs", "pitchStats")}>R</button></th>
                            <th className="hits align-right"><button onClick={() => handleSort("hits", "pitchStats")}>H</button></th>
                            <th className="home-runs align-right"><button onClick={() => handleSort("homeRuns", "pitchStats")}>HR</button></th>
                            <th className="strikeouts align-right"><button onClick={() => handleSort("strikeouts", "pitchStats")}>K</button></th>
                            <th className="walks align-right"><button onClick={() => handleSort("walks", "pitchStats")}>BB</button></th>
                            <th className="hit-by-pitches align-right"><button onClick={() => handleSort("hitBatters", "pitchStats")}>HBP</button></th>
                            <th className="whip align-right"><button onClick={() => handleSort("whip", "pitchStats")}>WHIP</button></th>
                        </tr>
                    </thead>
                    <tbody className="table-body">
                        {sortedPlayers.map((player) => (
                            <tr key={player.name}>
                                <td className="align-left">{player.name}</td>
                                <td className="align-right">{player.team}</td>
                                <td className="align-right">{player.pitchStats.wins}</td>
                                <td className="align-right">{player.pitchStats.losses}</td>
                                <td className="align-right">{player.pitchStats.era}</td>
                                <td className="align-right">{player.pitchStats.games}</td>
                                <td className="align-right">{player.pitchStats.gamesStarted}</td>
                                <td className="align-right">{player.pitchStats.saves}</td>
                                <td className="align-right">{player.pitchStats.innings}</td>
                                <td className="align-right">{player.pitchStats.earnedRuns}</td>
                                <td className="align-right">{player.pitchStats.runs}</td>
                                <td className="align-right">{player.pitchStats.hits}</td>
                                <td className="align-right">{player.pitchStats.homeRuns}</td>
                                <td className="align-right">{player.pitchStats.strikeouts}</td>
                                <td className="align-right">{player.pitchStats.walks}</td>
                                <td className="align-right">{player.pitchStats.hitBatters}</td>
                                <td className="align-right">{player.pitchStats.whip}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>}
        </section>
    )
}